
import screen from '../assets/screen.png';
import africaArena from '../assets/africaArena.png';
import forbes from '../assets/forbes.png';
import capeTown from '../assets/capeTown.png';
import grind from '../assets/grind.png';
import tweb from '../assets/tweb.png';
import digitalStreet from '../assets/digitalStreet.png';
import biz from '../assets/biz.png';
import thinkRoom from '../assets/thinkRoom.png';
import google from '../assets/google.png';
import countriesSmall from '../assets/countries-small.png';
import techCabal from '../assets/techCabal.png';
import countries from '../assets/countries.png';
import deadline from '../assets/deadline.png';
import management from '../assets/management.png';
import team from '../assets/team.png';
import contract from '../assets/contract generation.png';


export const images = {
    screen,
    africaArena,
    forbes,
    capeTown,
    grind,
    tweb,
    digitalStreet,
    biz,
    thinkRoom,
    techCabal,
    google,
    team,
    contract,
    management,
    deadline,
    countries,
    countriesSmall
};
