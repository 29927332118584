<template>
  <section class=" m-12">
    <h2 class="text-primary text-2xl md:text-4xl font-semibold md:leading-[50px]">
      FAQs
    </h2>
    <div v-for="(faq, index) in faqs" :key="index" class="mb-4 w-full">
      <div @click="toggleOpen(index)" class="flex items-center justify-between cursor-pointer my-4 text-sec ">
        <p class="md:text-base text-sm font-semibold">{{ faq.question }}</p>
        <span class="mr-2 text-lg">{{ isOpen(index) ? "-" : "+" }}</span>
      </div>
      <div v-if="isOpen(index)" class="mt-2">
        <p class="text-xs text-primary md:text-sm font-bold leading-[25px] md:leading-[35px]">{{ faq.answer }}</p>
      </div>
      <hr class="my-2 border-primary">
    </div>
  </section>
</template>
  
<script>
import { faqs } from '@/data/faq'; // Adjust the import path if necessary

export default {
  data() {
    return {
      openIndex: null,
      faqs, // Assign the imported FAQ data to the component's data
    };
  },
  methods: {
    toggleOpen(index) {
      this.openIndex = this.openIndex === index ? null : index;
    },
    isOpen(index) {
      return this.openIndex === index;
    },
  },
};
</script>
