<template>
  <div class="mt-4 mx-4 flex space-x-4">
    <div class="flex flex-col ml-4 gap-4">
      <div
        v-if="isQuestionAnswered"
        class="w-4 h-4 bg-green-600 bg-opacity-10 rounded-full border border-green-600 flex items-center justify-center"
      >
        <img src="@/assets/mdi_tick.svg" alt="Logo" />
      </div>
      <div
        v-else
        class="w-4 h-4 bg-gray-200 bg-opacity-10 rounded-full border border-gray-400 flex items-center justify-center"
      >
        <img src="@/assets/tag.svg" alt="Logo" />
      </div>
      <div class="w-[1px] h-24 border border-stone-200 ml-2"></div>
    </div>

    <div class="">
      <div class="text-sm font-bold ">{{ questionData.title }}</div>
      <div class="text-xs text-gray-500 mb-4 leading-[18px]">
        {{ questionData.text }}
        <RadioSelect
          :options="questionData.options"
          :preSelectedOption="preSelectedOption"
          @change="handleRadioChange"
        />
      </div>
    </div>
  </div>
  <div class="ml-16  border border-stone-200"></div>
</template>

<script>
// Importing necessary components and dependencies
import RadioSelect from '../UI/RadioSelect.vue';
import { ref } from 'vue';

export default {
  // Props for receiving data from the parent component
  props: {
    questionData: {
      type: Object,
      required: true,
    },
    preSelectedOption: {
      type: String,
      default: null,
    },
  },
  components: {
    RadioSelect,
  },
  setup(props, { emit }) {
    // Reactive variables for tracking question status and selected option
    const isQuestionAnswered = ref(false);
    const selectedOption = ref(props.preSelectedOption);

    // Function to handle radio button change
    const handleRadioChange = (score, report) => {
      // Emit an event to notify the parent component about the question being answered
      emit('question-answered', {
        questionId: props.questionData.id,
        score: score,
        report: report
      });

      // Update the question status based on the selected option
      isQuestionAnswered.value = true;
    };

    return {
      isQuestionAnswered,
      selectedOption,
      handleRadioChange,
    };
  },
};
</script>
