<template>
  <div class="container mx-auto text-center my-16">
    <h2
      class="mx-8 md:mx-24 lg:mx-28 my-8 md:my-12 text-zinc-750 text-4xl md:text-5xl font-semibold leading-tight tracking-wide"
    >
      Meet Our Dynamic Team
    </h2>
    <p class="mx-auto text-zinc-800 md:leading-7 tracking-wide my-4 w-full md:w-2/3">
      We are a diverse group of professionals committed to revolutionizing the contracting experience. Our team is here to provide innovative solutions that cater to your business needs.
    </p>

    <div class="flex flex-wrap justify-center gap-x-8 gap-y-8 mx-4 md:mx-14 my-8">
      <div
        v-for="(member, index) in teamMembers"
        :key="index"
        class="flex flex-col items-start bg-white shadow-md rounded-lg overflow-hidden w-64 md:w-80"
      >
        <div class="w-full h-[55%] flex items-center justify-center bg-zinc-100">
          <img
            v-if="member.image"
            :src="member.image"
            :alt="`${member.name} photo`"
            class="w-full h-full object-cover"
          />
          <div
            v-else
            class="flex items-center justify-center w-full h-full text-zinc-500 text-3xl md:text-4xl font-semibold uppercase"
          >
            {{ getInitials(member.name) }}
          </div>
        </div>
        <div class="p-6 md:p-8">
          <div class="text-zinc-800 text-lg md:text-xl font-semibold mb-1">
            {{ member.name }}
          </div>
          <div class="text-zinc-800 text-sm md:text-base mb-2">
            {{ member.position }}
          </div>
          <div class="text-zinc-800 text-xs md:text-sm">
            {{ member.description }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    teamMembers: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getInitials(name) {
      if (!name) return "";
      const nameParts = name.split(" ");
      const initials = nameParts.map(part => part.charAt(0)).join("");
      return initials;
    },
  },
};
</script>
