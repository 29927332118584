<template>
    <div
      class="bg-cover bg-center relative p-8"
      :style="`background-image: url(${require('@/assets/bg.png')})`"
    >
      <section class="w-full md:w-[60%] mx-auto">
        <h2
          class="my-4 text-center text-zinc-750 text-4xl md:text-5xl tracking-wide font-semibold"
          :style="'line-height : 1.5'"
        >
          Community Social Initiative (CSI)- Supporting GBV Victims
        </h2>
  
        <p class="text-center text-zinc-800 md:leading-7 tracking-wide my-4">
            CSI initiative , each attorney on the platform has to give back 2 hours per week to GBV victims.
        </p>
        <!-- <div
          class="flex flex-row items-center my-8 justify-center space-x-4 md:space-y-0 space-x-0 md:space-x-4"
        >
        <a href="https://linktr.ee/lawyeredup" target="_blank" rel="noopener noreferrer">
              <Button buttonText="Request a Demo" :clickHandler="handleClick" />
            </a>
        </div> -->
      </section>
    </div>
    <section class="mx-12">
      <section :class="sectionClasses">
        <FeatContent
          class="w-full lg:w-2/5"
          :title="'Community Social Initiative (CSI) – Supporting GBV Victims'"
          :description="`At Lawyered Up, we believe in the power of giving back to our community and making a positive impact. As part of our commitment to social responsibility, we have implemented a Community Social Initiative (CSI) focused on supporting victims of Gender-Based Violence (GBV).`"
        />
        <div :class="imageContainer">
          <img src="@/assets/law.png" alt="" style="border-radius: 15px;"/>
        </div>
      </section>

      <section :class="sectionClasses">
        <div class="mt-12">
        <h2 class="text-sky-950 text-2xl md:text-2xl font-semibold md:leading-[60px] leading-[30px]" style="color:#1D4A5B ;">Our Commitment:</h2>
    <p class=" text-zinc-800 md:text-base text-sm md:font-normal font-light md:leading-[35px] leading-[25px]">Each attorney on the Lawyered Up platform dedicates three hours per week to providing pro bono legal assistance to GBV victims. We understand the critical need for legal support in these cases and aim to ensure that every victim has access to the justice and protection they deserve.</p>
    </div>
      </section>

      <section :class="sectionClasses">
        <div class="mt-12">
        <h2 class="text-sky-950 text-2xl md:text-2xl font-semibold md:leading-[60px] leading-[30px]" style="color:#1D4A5B ;">Legal Advice: Offering free consultations to help GBV victims understand their legal rights and options</h2>
    <p class=" text-zinc-800 md:text-base text-sm md:font-normal font-light md:leading-[35px] leading-[25px]">Documentation Assistance and consultation : Helping with the preparation and filing of necessary legal documents.
Court Representation: Providing representation in court hearings and legal proceedings for 2 hours.</p>
    </div>
      </section>

      <section :class="sectionClasses">
        <div class="mt-12">
        <h2 class="text-sky-950 text-2xl md:text-2xl font-semibold md:leading-[60px] leading-[30px]" style="color:#1D4A5B ;">Why We Do It:</h2>
    <p class=" text-zinc-800 md:text-base text-sm md:font-normal font-light md:leading-[35px] leading-[25px]">We recognize that victims of GBV often face significant barriers in accessing legal help. By dedicating our time and expertise, we aim to remove these barriers and offer crucial support during challenging times. Our attorneys are not only skilled professionals but also compassionate advocates committed to making a difference.</p>
    </div>
      </section>

      <section :class="sectionClasses">
        <div class="mt-12">
        <h2 class="text-sky-950 text-2xl md:text-2xl font-semibold md:leading-[60px] leading-[30px]" style="color:#1D4A5B ;">Get Involved:</h2>
    <p class=" text-zinc-800 md:text-base text-sm md:font-normal font-light md:leading-[35px] leading-[25px]">If you or someone you know is a victim of GBV and needs legal assistance, please reach out to us. Our team is here to help, and we are dedicated to providing the support and guidance needed to achieve justice and safety.</p>
    </div>
      </section>
      <section :class="sectionClasses">
        <div class="mt-12">
    <p class=" text-zinc-800 md:text-base text-sm md:font-normal font-light md:leading-[35px] leading-[25px]">At Lawyered Up, we stand with GBV victims and are committed to fostering a safer, more just community for all.</p>
    </div>
      </section>

    </section>
  </template>
  <script>
  import { Icon } from "@iconify/vue";
  import Button from "../UI/Button.vue";
  import FeatContent from "./FeatContent.vue";
  import { ref } from "vue";
  
  export default {
    components: { Icon, Button, FeatContent },
    setup() {
      const sectionClasses = ref(
        "  lg:flex lg:flex-row lg:justify-between lg:item-center lg:justify-center"
      );
      const imageContainer = ref("w-full md:w-80% md:block lg:w-1/2 py-8");
      return { sectionClasses, imageContainer };
    },
  };
  </script>
  