<!-- Assessment Section Template -->
<template>
  <div id="assessment-section" class="bg-white rounded-lg w-full shadow-md md:my-6 text-black">
    <!-- Title and Subtitle -->
    <div class="py-4 px-6 font-medium text-center">
      <div class="text-primary text-[35px] md:text-[50px] md:leading-[68px]">
        Contract Score Assessment
      </div>
      <div class="text-secondary text-[17px] md:leading-[27px]">
        Find out your contract processes score in seconds with our quick and
        easy assessment.
      </div>
    </div>
    <div class="border-t border-stone-200"></div>

    <!-- Loop through all questions and render the Question component for each -->
    <Question
      v-for="(question, index) in questions"
      :key="index"
      :question-data="question"
      @question-answered="updateTotalScore(index, $event)"
    />
    <!-- Email Input -->
    <div class="my-8 mx-12 text-sm">
      <input
        type="email"
        v-model="email"
        placeholder="Enter your email address"
        class="w-full px-2 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
      />
      <!-- <span class="text-red-200 text-xs" v-if="email && !validateEmail(email.value)">Please enter a valid email address.</span> -->
    </div>

    <!-- Submit Assessment Button -->
    <div class="m-12 pb-4">
      <Button buttonText="Submit Assessment" @click="handleAssessment" />
    </div>
  </div>
</template>

<script>
// Importing necessary components and dependencies
import validateEmail from "../../composables/validateEmail";
import Question from "@/components/Assessment/Question.vue";
import { questions } from "../questions";
import Button from "@/components/UI/Button.vue";
import { ref, inject } from "vue";
import { projectFunctions } from "../../firebase";
import { httpsCallable } from "firebase/functions";

export default {
  components: { Question, Button },
  name: "AssessmentInfo",
  props: {
    showModal: Boolean,
    openModal: Function, // Parent function to open the modal
  },
  setup(props) {
    // Injecting store from Vue context
    const store = inject("store");

    // Setting up reactive variables
    const email = ref("");
    const allQuestions = ref(questions);
    const questionScores = ref([]);

    // Update total score function
    const updateTotalScore = (index, { questionId, score, report }) => {
      // Check if the score is a number
      if (typeof score === "number") {
        // Find the question in the array
        const existingScoreIndex = questionScores.value.findIndex(
          (item) => item.id === questionId
        );
        // If the question is found, update the score; otherwise, add a new entry
        if (existingScoreIndex !== -1) {
          questionScores.value[existingScoreIndex].score = score;
        } else {
          store.reports.value[index] = report;
          questionScores.value.push({ id: questionId, score: score, report: report });
        }
      }
    };
    // Send email function
    const send = httpsCallable(projectFunctions, "assessmentNotification");
    // Handle assessment submission function
    const handleAssessment = async () => {
      console.log(email.value);
// Check if all questions are answered and email is valid
      if (questionScores.value.length === 5 && validateEmail(email.value)) {
        // Calculate total score
        const totalScore = questionScores.value.reduce(
          (total, item) => total + item.score * 4,
          0
        );
        // Update store with total score
        store.score.value = totalScore;
        store.email.value = email.value;
        await send({
            email: email.value,
            report: totalScore,
          });
        // Notify the parent component to open the modal
        props.openModal();

      } else {
        alert("Please answer all questions and input your correct email address before submitting.");
      }
    };

    return {
      questions: allQuestions,
      handleAssessment,
      updateTotalScore,
      email
    };
  },
};
</script>
