<template>
    <section class="flex lg:mt-48 md:ml-14 md:my-12 md:space-x-12">
        <section class="hidden md:block">
<div class="flex flex-col md:flex-row ">
    <img src="@/assets/nam.svg" alt="Logo" />
    <div class="mx-4 mb-6">
        <img src="@/assets/32.svg" alt="Logo" class="ml-2 mt-4"/>
        <img src="@/assets/man.svg" alt="Logo" />
    </div>
    <img src="@/assets/yel.svg" alt="Logo" class="mt-32"/>
</div>
<img src="@/assets/hold.svg" alt="Logo" class="mt-[-35px]"/>
<div>
    
</div>





</section>
<section>

</section>
<section class="w-full md:w-[50%] mt-12  py-8 px-4"> 
    <div class=" text-primary text-4xl font-semibold leading-[48px]">Unlocking Your Contract Improvement</div>
    <div class=" text-secondary text-base font-medium leading-[24px]">Our contract score assessment provides you with a quantitative measure of the quality, efficiency, and compliance of your legal contracts processes. This evaluation helps you identify areas for improvement and enhances overall contract performance. Our contract score offers transparency and insights, empowering you to make informed decisions about your contract process</div>
    <div class="my-4 md:my-8">
      <router-link to="/assessment">
      <Button buttonText="Take Assessment" />
    </router-link>
    </div>
</section>
    </section>
</template>

<script>
import Button from "@/components/UI/Button.vue";

export default {
  components: {
    Button,
  },
  setup() {


    return {

    };
  },
};
</script>