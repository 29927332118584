<template>
  <nav class="p-4 text-nav text-sm">
    <div class="flex items-center justify-between">
      <!-- Menu Icon for smaller screens -->
      <div class="md:hidden cursor-pointer" @click="toggleMobileMenu">
        <Icon icon="feather:menu" class="w-6 h-6" />
      </div>

      <!-- Navigation for larger screens -->
      <div class="hidden md:flex space-x-8">
        <!-- Display for nav-items without items -->
        <router-link
          v-for="navItem in filteredNavigation.withoutItems"
          :key="navItem.route"
          :to="navItem.route"
          :class="{ 'text-nav': activeNav === navItem.name }"
          @click="setActiveNav(navItem.name)"
          class="hover:opacity-80 transition-colors duration-300"
        >
          {{ navItem.name }}
        </router-link>

        <!-- Display for nav-items with items -->
        <div
          v-for="navItem in filteredNavigation.withItems"
          :key="navItem"
          class="relative"
          @mouseover="showDropdown(navItem.name)"
          @mouseleave="hideDropdown"
        >
          <div
            class="cursor-pointer flex items-center"
            :class="{ 'text-nav': activeDropdown === navItem.name }"
          >
            {{ navItem.name }}
            <Icon icon="ep:arrow-down" class="ml-1 w-4 h-2" />
          </div>
          <transition name="fade">
            <div
              v-if="activeDropdown === navItem.name"
              class="bg-white absolute mt-4 px-4 py-4 w-[500px] rounded shadow-lg grid grid-cols-2 gap-4"
              style="z-index: 3"
            >
            <router-link
                v-for="(item, index) in navItem.items"
                :key="index"
                :to="item.route"
                class="flex items-start space-x-2 cursor-pointer p-2 hover:bg-gray-100   rounded-md transition-colors duration-300"
                @click="setActiveNav(item.title)"
              >
                <Icon :icon="item.icon" class="w-4 h-4 mt-1 text-nav" />
                <div class="flex flex-col" >
             
                  <span class="font-semibold">{{ item.title }}</span>
                  <span class="text-xs text-sec">{{
                    item.description
                  }}</span>
                </div>
              </router-link>
            </div>
          </transition>
        </div>
      </div>

      <!-- Mobile Menu for smaller screens -->
      <transition name="slide-fade">
  <div
    v-if="showMobileMenu"
    class="fixed inset-0 bg-black bg-opacity-50 z-50"
  >
    <div
      class="absolute top-0 right-0 h-full w-full bg-white shadow-lg p-8 text-nav"
    >
      <div class="flex justify-between items-center mb-8">
        <span class="font-bold text-xl">Menu</span>
        <Icon
          icon="feather:x"
          class="w-6 h-6 cursor-pointer"
          @click="toggleMobileMenu"
        />
      </div>
      <div class="flex flex-col space-y-4">
        <div v-for="navItem in navigation" :key="navItem.route">
          <!-- Check if the item has sub-items -->
          <div v-if="navItem.items">
            <div
              class="flex justify-between items-center cursor-pointer"
              @click="toggleDropdown(navItem.name)"
            >
              <span>{{ navItem.name }}</span>
              <Icon
                icon="ep:arrow-down"
                class="ml-1 w-4 h-4"
              />
            </div>
            <transition name="fade">
              <div
                v-if="activeDropdown === navItem.name"
                class="mt-4 pl-4 flex flex-col space-y-2"
              >
                <div
                  v-for="subItem in navItem.items"
                  :key="subItem.route"
                >
                  <router-link
                    :to="subItem.route"
                    class="text-sm hover:text-nav flex items-start space-x-2"
                  >
                    <Icon :icon="subItem.icon" class="w-4 h-4" />
                    <div class="flex flex-col">
                      <span>{{ subItem.title }}</span>
                      <span class="text-xs text-nav opacity-75">
                        {{ subItem.description }}
                      </span>
                    </div>
                  </router-link>
                </div>
              </div>
            </transition>
          </div>

          <!-- Items without sub-items -->
          <router-link
            v-else
            :to="navItem.route"
            class="flex items-center cursor-pointer"
          >
            <span>{{ navItem.name }}</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</transition>

    </div>
  </nav>
</template>

<script>
import { ref, computed } from "vue";
import { Icon } from "@iconify/vue";

export default {
  components: { Icon },
  setup() {
    const activeDropdown = ref(null);
    const activeNav = ref(null);

    const toggleDropdown = (dropdown) => {
      activeDropdown.value =
        activeDropdown.value === dropdown ? null : dropdown;
    };

    const showDropdown = (dropdown) => {
      activeDropdown.value = dropdown;
    };

    const hideDropdown = () => {
      activeDropdown.value = null;
    };

    const filteredNavigation = computed(() => {
      return {
        withItems: navigation.filter(
          (navItem) => navItem.items && navItem.items.length > 0
        ),
        withoutItems: navigation.filter(
          (navItem) => !navItem.items || navItem.items.length === 0
        ),
      };
    });

    const setActiveNav = (nav) => {
      activeNav.value = nav;
      activeDropdown.value = null;
    };

    const navigation = [
      {
        name: "Home",
        route: "/",
      },
      {
        name: "Our Team",
        route: "/the-team",
      },
      {
        name: "Solutions",
        route: "/solutions",
        items: [
          {
            title: "People and HR",
            route: "/people-and-hr",
            icon: "fluent:people-16-regular",
            description: "Manage your team efficiently",
          },
          {
            title: "Legal",
            route: "/legal",
            icon: "map:lawyer",
            description: "Expert legal advice",
          },
          // {
          //   title: "Lawyer Network",
          //   route: "/lawyer",
          //   icon: "mdi:account-tie",
          //   description: "Connect with our lawyer network",
          // },
          // {
          //   title: "LLC Setup",
          //   route: "/llc",
          //   icon: "mdi:briefcase",
          //   description: "Get your business registered",
          // },
          {
            title: "Social Responsibility",
            route: "/social",
            icon: "mdi:heart",
            description: "Contribute to society",
          },
        ],
      },
      {
        name: "Contact",
        route: "/contact",
        items: [
          {
            title: "Contact Us",
            route: "/contact-us",
            icon: "mdi:phone",
            description: "Reach out to us",
          },
        ],
      },
      // {
      //   name: "Scorecard",
      //   route: "/assessment",
      // },
    ];

    const showMobileMenu = ref(false);

    const toggleMobileMenu = () => {
      showMobileMenu.value = !showMobileMenu.value;
    };

    return {
      activeDropdown,
      activeNav,
      toggleDropdown,
      showDropdown,
      hideDropdown,
      setActiveNav,
      navigation,
      filteredNavigation,
      showMobileMenu,
      toggleMobileMenu,
    };
  },
};
</script>

<style scoped>
/* Animations */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(100%);
}
</style>