import { createRouter, createWebHistory } from 'vue-router'
import AssessmentMarketing from '../views/AssessmentMarketing.vue'
import Assessment from '../views/Assessment.vue'
import WebHome from '../views/WebHome'
import Pricing from '../views/Pricing.vue'
import About from '../views/About.vue'
import Legal from '../views/Legal.vue'
import Contact from '../views/Contact.vue'
import People from '../views/People.vue'
import Lawyer from '../views/Lawyer.vue'
import LLC from '../views/LLC.vue'
import Social from '../views/Social.vue'
import Privacy from '../views/Privacy.vue'
import Team from '../views/Team.vue'
import CareersPage from '@/views/CareersPage.vue';
import PositionDetails from '@/views/PositionDetails.vue';

const routes = [
  {
    path: '/',
    name: 'WebHome',
    component: WebHome
  },
  {
    path: '/AssessmentMarketing',
    name: 'AssessmentMarketing',
    component: AssessmentMarketing
  },
  {
    path: '/assessment',
    name: 'Assessment',
    component: Assessment
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: Pricing
  },
  {
    path: '/assessment',
    name: 'Assessment',
    component: Assessment
  },
  {
    path: '/about-us',
    name: 'About',
    component: About
  },
  {
    path: '/careers',
    name: 'Careers',
    component: CareersPage,
  },
  {
    path: '/careers/:slug',
    name: 'PositionDetails',
    component: PositionDetails,
  },
  {
    path: '/the-team',
    name: 'Team',
    component: Team
  },
  {
    path: '/people-and-hr',
    name: 'People',
    component: People
  },
  {
    path: '/contact-us',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/legal',
    name: 'Legal',
    component: Legal
  },
  {
    path: '/lawyer',
    name: 'Lawyer',
    component: Lawyer
  },
  {
    path: '/llc',
    name: 'LLC',
    component: LLC
  }, 
  {
    path: '/social',
    name: 'Social',
    component: Social
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
})

export default router
