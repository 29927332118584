<template>
    <div>
      <!-- Navigation Header -->
      <NavHeaderVue />
  
      <!-- Position Details Section -->
      <div class="container mx-auto py-16">
        <div class="text-center mb-8">
          <h1 class="text-zinc-750 text-3xl md:text-4xl font-bold">{{ position.title }}</h1>
          <p class="text-zinc-800 text-base md:text-lg mb-2">{{ position.location }}</p>
        </div>
        <div class="bg-white shadow-lg rounded-lg p-6 md:p-8">
          <p class="text-zinc-800 text-base md:text-lg leading-relaxed">
            {{ position.details }}
          </p>
        </div>
        <div class="text-center mt-8">
          <router-link to="/careers">
            <button class="inline-flex items-center bg-primary text-white text-xs py-2 px-4 rounded hover:bg-opacity-90 transition-opacity">
              Back to Careers
              <Icon icon="weui:arrow-outlined" class="ml-2" />
            </button>
          </router-link>
        </div>
      </div>
  
      <!-- Footer -->
      <Footer />
    </div>
  </template>
  
  <script>
  import { useRoute } from 'vue-router';
  import { positions } from '@/data/careers';
  import NavHeaderVue from '@/components/layout/NavHeader.vue';
  import Footer from '@/components/layout/Footer.vue';
  import { Icon } from '@iconify/vue';
  
  export default {
    components: {
      NavHeaderVue,
      Footer,
      Icon,
    },
    setup() {
      const route = useRoute();
      const slug = route.params.slug;
      const position = positions.find((pos) => pos.slug === slug);
  
      return {
        position,
      };
    },
  };
  </script>
  