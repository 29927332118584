<template>
   <NavHeaderVue/>
   <IntroDashboard/>
   <Clients/>
   <!-- <Google/> -->
   <Features/>
   <FAQ/>
   <Footer/>
  </template>
  <script>

import NavHeaderVue from '@/components/layout/NavHeader.vue';
import IntroDashboard from '@/components/Dashboard/IntroDashboard.vue'
import Clients from '@/components/Dashboard/Clients.vue';
import Features from '@/components/Dashboard/Features.vue';
import Footer from '@/components/layout/Footer.vue';
import FAQ from '@/components/Dashboard/FAQ.vue';
import Google from '@/components/Dashboard/Google.vue';

  export default {
    components: {
      NavHeaderVue, IntroDashboard, Clients, Features, Footer, FAQ, Google
    },
    setup() {
  
      return {
     
      }
    }
  };
  </script>
  