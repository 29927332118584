<template>
    <div class="bg-white z-0 rounded-lg md:w-full sm:w-full shadow-md md:my-6 text-black">
      <div class="py-4 px-6">
        <div class="text-sm font-semibold">Contract Score Assessment</div>
        <div class="text-xs">Check if you qualify in seconds</div>
      </div>
      <div class="border-t border-stone-200"></div>
  
     <!-- Loop through the top three questions and render the Question component for each -->
    <Question v-for="(question, index) in topThreeQuestions" :key="index" :question-data="question"    :preSelectedOption="index === 0 ? '1-4 Weeks' : null" />
    </div>
  </template>
  
  <script>
  import Question from "@/components/Assessment/Question.vue";
import { questions } from "../questions";
  import { ref } from "vue";
  export default {
    components: { Question },
    name: "AssessmentInfo",
    setup() {
       // Setting up reactive variable for top three questions
    const topThreeQuestions = ref(questions.slice(0, 3));

    return {
      topThreeQuestions,
    };
  },
  };
  </script>
  