<template>

    <div
    class="bg-cover bg-center relative  p-8 "
        :style="`background-image: url(${require('@/assets/bg.png')})`"
    >
    <section class="w-full md:w-[60%] mx-auto">
        <h2 class="my-4 text-center text-zinc-750 text-4xl md:text-5xl  tracking-wide font-semibold" :style="'line-height : 1.5'">
    <span class="leading-4 text-white bg-primary px-2 rounded">Streamline</span>
    Talent Management Efficiently
</h2>


    
        <p class="text-center text-sec md:leading-7 tracking-wide my-4">
            HR doesnt need to be hard. Vega empowers your HR team to make it easy.
        </p>
        <div class="flex flex-row items-center my-8 justify-center space-x-4 md:space-y-0 space-x-0 md:space-x-4">

          <a href="https://linktr.ee/lawyeredup" target="_blank" rel="noopener noreferrer">
            <Button buttonText="Request a Demo" :clickHandler="handleClick" />
          </a>
        </div>
      </section>
    </div>
    <section class="mx-12 ">
        <section :class="sectionClasses" >
      <FeatContent
      class="w-full lg:w-2/5"
        :title="'No time wasted on paperwork'"
        :description="`Save your HR team the time on tedious manual work - Create employment contracts and onboarding documents instantly with Vega.`"
      />
      <div :class="imageContainer">
        <img src="@/assets/talent1.svg" alt="" />
      </div>
    </section>
    <section :class="sectionClasses" class="flex flex-col-reverse">
        <div :class="imageContainer">
        <img src="@/assets/wait.png" alt="" />
      </div>
      <FeatContent
      class="w-full lg:w-2/5"
        :title="'Say Goodbye to Waiting on Legal'"
        :description="`Effortlessly Create, Manage, and Securely Store All Your Documents. Never miss a beat with smart reminders.`"
      />
    
    </section>
    <section :class="sectionClasses" >
      <FeatContent
      class="w-full lg:w-2/5"
        :title="'Realtime Compliance.'"
        :description="`With our template library, you don't have to worry about keeping up with the law as it changes- We do that for you.`"
      />
      <div :class="imageContainer">
        <img src="@/assets/talent3.svg" alt="" />
      </div>
    </section>
      </section>
</template>
<script>
import { Icon } from "@iconify/vue";
import Button from "../UI/Button.vue";
import FeatContent from "./FeatContent.vue";
import { ref } from "vue";

export default {
  components: { Icon, Button, FeatContent },
  setup() {
    const sectionClasses = ref("  lg:flex lg:flex-row lg:justify-between lg:item-center lg:justify-center");
const imageContainer = ref("w-full md:w-80% md:block lg:w-1/2 py-8")
    return {sectionClasses, imageContainer};
  },
};
</script>
