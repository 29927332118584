export const questions = [
  {
    id: 'Q1',
    title: "Question 1 - Contract Initiation Time",
    text: "How long does it typically take for your company to create a new business contract from the initial request?",
    options: [
      { name: "Instantly", score: 5, report: 'Your contract generation process is top-notch. Vega can help maintain this excellence.' },
      { name: "A matter of hours", score: 4, report: 'There is room for improvement in the efficiency of your contract generation process; Vega can enhance this aspect.' },
      { name: "1-3 Days", score: 3, report: 'Your contract generation process needs refinement, and Vega can significantly improve its efficiency.' },
      { name: "A week", score: 2, report: 'Consider adopting Vega to revamp and streamline your contract generation process' },
      { name: "More than a Month", score: 1, report: 'Your contract generation process is a bottleneck, and Vega is crucial for instant improvement.' },
    ],
  },
  {
    id: 'Q2',
    title: "Question 2 - Contract Storage and Retrieval",
    text: "How do you currently store and manage your contracts?",
    options: [
      { name: "Advanced real-time system", score: 5, report: 'Well done on storage! Vega can further optimize your contract storage and management.' },
      { name: "Standard document storage system", score: 4, report: 'Good job on storage, but Vega offers advanced features for a more organized contract management system.' },
      { name: "local server or device system", score: 3, report: 'Consider upgrading to Vega for a more efficient and centralized contract storage solution.' },
      { name: "Physical filing system", score: 2, report: 'Vega can digitize and streamline your contract storage, enhancing accessibility.' },
      { name: "No organized storage system", score: 1, report: 'Urgent improvement needed! Vega will revolutionize your contract storage and retrieval.' },
    ],
  },
  {
    id: 'Q3',
    title: "Question 3 - Keep Everyone On The Same Page",
    text: "How do you currently track the status of your ongoing transactions?",
    options: [
      { name: "Real-time transaction manager", score: 5, report: 'Excellent Status Tracking! Vega complements your real-time tracking capabilities for seamless transaction management.' },
      { name: "Manual tracking in a dedicated system", score: 4 , report: 'Good job on status tracking, but Vega can automate and enhance your transaction tracking process.'},
      { name: "Spreadsheet-based tracking", score: 3, report: 'Upgrade to Vega for a more sophisticated and automated approach to transaction tracking.' },
      { name: "Periodic reviews with stakeholders", score: 2, report: 'Consider Vega for real-time updates and increased transparency in transaction management.' },
      { name: "No structured tracking in place", score: 1, report: 'Urgent need! Vega will provide a structured approach for tracking ongoing transactions.' },
    ],
  },
  {
    id: 'Q4',
    title: "Question 4 - Compliance. Never Miss A Deadline, Filing or Renewal",
    text: "How do you avoid missing important contract-related deadlines or key compliance tasks?",
    options: [
      { name: "Automated task reminder", score: 5, report: 'Impressive obligation management! Vega can help maintain this level of precision and prevent any potential oversights.' },
      { name: "Manual tracking of tasks", score: 4 , report: 'Consider Vega to minimize occasional oversights and ensure timely management of tasks and deadlines.'},
      { name: "Spreadsheet-based tracking", score: 3, report: 'Vega can help you transition to a more proactive approach, reducing the frequency of missed deadlines.' },
      { name: "Periodic reviews with stakeholders", score: 2, report: 'Adopting Vega will significantly enhance your ability to meet deadlines and manage tasks effectively.' }, 
      { name: "No system tracking in place", score: 1 , report: 'Urgent improvement needed! Vega is essential to prevent daily oversights and improve your overall task management.'},
    ],
  },
  {
    id: 'Q5',
    title: "Question 5 - Transaction Visibility",
    text: "What level of control and visibility do you have over your ongoing contractual obligations and tasks?",
    options: [
      { name: "Comprehensive control and visibility", score: 5, report: 'Impressive work on visibility! Vega can complement your existing control for an even smoother experience.' },
      { name: "Adequate control and visibility", score: 4, report: 'Good work on visibility, but Vega can provide additional features for enhanced control and visibility' },
      { name: "Limited control and visibility", score: 3 , report: 'Consider Vega to boost your control and visibility over contractual obligations and tasks'},
      { name: "Minimal control and visibility", score: 2, report: 'Vega can significantly improve your oversight of ongoing obligations and tasks' },
      { name: "No control and visibility", score: 1, report: 'Urgent need! Vega will empower you with control and visibility over your contractual commitments' },
    ],
  },
];
