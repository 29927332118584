<template>
    <NavHeaderVue />
    <TeamSection :teamMembers="teamMembers" />
    <JoinOurTeamSection />
    <Footer />
  </template>
  
  <script>
  import NavHeaderVue from '@/components/layout/NavHeader.vue';
  import Footer from '@/components/layout/Footer.vue';
  import TeamSection from '@/components/Team/TeamSection.vue';
  import JoinOurTeamSection from '@/components/Team/JoinOurTeamSection.vue';
  import teamData from '@/data/teamData.js';
  
  export default {
    components: {
      NavHeaderVue,
      TeamSection,
      JoinOurTeamSection,
      Footer,
    },
    setup() {
      return {
        teamMembers: teamData,
      };
    },
  };
  </script>
  