<template>
    <div class="mt-8">
    <NavHeaderVue/>
    <LLCInfo/>
    <Clients/>
    <Footer/>
  </div>
   </template>
   <script>
  
  import NavHeaderVue from '@/components/layout/NavHeader.vue';
  
  import Clients from '@/components/Dashboard/Clients.vue';
  import Features from '@/components/Dashboard/Features.vue';
  import Footer from '@/components/layout/Footer.vue';
  import LLCInfo from '@/components/Dashboard/LLCInfo.vue';
  
  
   export default {
     components: {
       NavHeaderVue, Clients, Features, Footer, LLCInfo
     },
     setup() {
   
       return {
      
       }
     }
   };
   </script>
   