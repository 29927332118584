<template>
  <section class="mt-12 md:mt-12 ">
    <div class="text-white hidden md:block">
      <div class="mx-12 relative flex md:flex-col flex-row ">
        <img
          src="@/assets/mask.svg"
          alt="Background Image"
          class="w-full md:h-[30%] h-[100%]"
        />
        <FooterBody />
      </div>
    </div>
    <div class="text-white md:hidden block">
      <div class="mx-12 relative flex md:flex-col flex-row ">
        <img
        src="@/assets/footerbg.png"
          alt="Background Image"
         class="h-auto w-full"
        />
        <FooterBody />
      </div>
    </div>
    <FooterInfo />
  </section>
</template>
  
  <script>
import FAQ from "../Dashboard/FAQ.vue";

import FooterInfo from "./FooterInfo.vue";
import FooterBody from "./FooterBody.vue";

export default {
  components: { FAQ, FooterInfo, FooterBody },
};
</script>
