<template>
  <footer class="mt-6 p-6 md:p-12 ">
    <div class="flex flex-col md:flex-row justify-between space-y-8 md:space-y-0">
      
      <div class="flex flex-col items-start space-y-4 w-full md:w-1/4">
        <div class="flex items-center space-x-3">

          <!-- <img src="@/assets/lu.svg" alt="Company Logo" class="h-10 w-10" /> -->
          <span class="text-2xl font-semibold text-primary">Lawyered Up</span>
        </div>
        <p class="text-sm text-sec">
          Lawyered Up is powered by Vega and utilizes cutting-edge technology to automate your contractual needs.
        </p>
      </div>

      <!-- Quick Links Section -->
      <div class="flex flex-col space-y-3 w-full md:w-1/4">
        <h3 class="text-lg font-semibold text-primary">Quick Links</h3>
        <router-link :class="linkStyle" to="/people-and-hr">
          <Icon icon="mdi:account-group" class="h-4 w-4 mr-2 inline" /> People and HR
        </router-link>
        <router-link :class="linkStyle" to="/legal">
          <Icon icon="mdi:scale-balance" class="h-4 w-4 mr-2 inline" /> Legal
        </router-link>
        <!-- <router-link :class="linkStyle" to="/lawyer">
          <Icon icon="mdi:gavel" class="h-4 w-4 mr-2 inline" /> Lawyer Network
        </router-link>
        <router-link :class="linkStyle" to="/llc">
          <Icon icon="mdi:domain" class="h-4 w-4 mr-2 inline" /> LLC Setup
        </router-link> -->
        <router-link :class="linkStyle" to="/about-us">
          <Icon icon="mdi:information-outline" class="h-4 w-4 mr-2 inline" /> About Us
        </router-link>
        <router-link :class="linkStyle" to="/the-team">
          <Icon icon="mdi:account-multiple" class="h-4 w-4 mr-2 inline" /> Our Team
        </router-link>
        <router-link :class="linkStyle" to="/contact-us">
          <Icon icon="mdi:email" class="h-4 w-4 mr-2 inline" /> Contact Us
        </router-link>
      </div>

      <!-- Legal Section -->
      <div class="flex flex-col space-y-3 w-full md:w-1/4">
        <h3 class="text-lg font-semibold text-primary">Legal</h3>
        <a :class="linkStyle" target="_blank" href="https://firebasestorage.googleapis.com/v0/b/vegabymypocketcounsel.appspot.com/o/assets%2Flawyeredup%2FTerms%20of%20Service%20Lawyered%20Up%20powered%20by%20Vega.pdf?alt=media&token=f028f496-1018-411d-8dea-ab5d0b16101c">
          <Icon icon="mdi:file-document-outline" class="h-4 w-4 mr-2 inline" /> Terms of Use
        </a>
        <router-link :class="linkStyle" to="/privacy">
          <Icon icon="mdi:shield-lock-outline" class="h-4 w-4 mr-2 inline" /> Privacy Policy
        </router-link>
      </div>
      
    </div>
  </footer>
</template>

<script>
import { Icon } from "@iconify/vue";
import { ref } from "vue";

export default {
  components: { Icon },
  setup() {
    const linkStyle = ref("text-sm text-sec hover:text-primary flex items-center");
    return {
      linkStyle,
    };
  },
};
</script>
