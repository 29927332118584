<template>
  <div class="">
    <div :class="['mt-2', 'flex', 'items-center', 'justify-center', 'w-28', 'h-28', 'border-2', 'rounded-full', getBorderColor]">
      <div class="text-center flex flex-col">
        <div class="text-xl font-bold">{{ getGrade }}</div>
        <div class="text-sm font-semibold">Your Grade</div>
      </div>
    </div>
  </div>
</template>
  
  
  <script>
import {  computed, inject } from "vue";



export default {
  setup() {
    const store = inject('store')
    const totalScore = store.score.value

    const getBorderColor = computed(() => {
      if (totalScore >= 80) {
        return 'border-green-500';
      } else if (totalScore >= 70) {
        return 'border-lime-500';
      } else if (totalScore >= 50) {
        return 'border-amber-500';
      } else if (totalScore >= 40) {
        return 'border-orange-500';
      } else {
        return 'border-red-500';
      }
    });

    const getGrade = computed(() => {
      if (totalScore >= 80) {
        return 'A';
      } else if (totalScore >= 70) {
        return 'B';
      }else if (totalScore >= 60) {
        return 'C';
      } else if (totalScore >= 50) {
        return 'D';
      } else if (totalScore >= 40) {
        return 'E';
      }else {
        return 'F';
      }
    });

 

    return {
      getBorderColor,
      getGrade,
      totalScore
    };
  }
};
</script>