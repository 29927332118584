<template>
    <div class="mt-12">
  <NavHeaderVue />
  <section class="my-8 mx-16">
    <h2
      class="mx-8 md:mx-24 lg:mx-28 my-4 text-center text-zinc-750 text-4xl md:text-5xl leading-tight tracking-wide font-semibold"
    >
Contact Us
    </h2>
    <div class="flex flex-col md:flex-row">
    <ContactInfo/>
      <ContactUs/>
    </div>
  
  
  </section>
  <!-- <Clients /> -->
  <Footer />
</div>
</template>
   <script>
import NavHeaderVue from "@/components/layout/NavHeader.vue";
import Clients from "@/components/Dashboard/Clients.vue";
import Features from "@/components/Dashboard/Features.vue";
import ContactUs from "@/components/ContactUs/ContactUs.vue";
import Footer from "@/components/layout/Footer.vue";
import ContactInfo from "@/components/ContactUs/ContactInfo.vue";

import { ref } from 'vue';

export default {
  components: {
    NavHeaderVue,
    Clients,
    Features,
    Footer,
    ContactUs,
    ContactInfo
  },
  setup() {
    const yellowIcon = ref("mr-4 mt-0.5 text-orange-500")
    return {yellowIcon};
  },
};
</script>

