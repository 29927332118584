<template>
  <section>
    <div class="w-full flex justify-between mt-12">
      <div>
        <div class="text-neutral-600 text-2xl ml-4 leading-[27px]">
          {{ progressValue }}%
        </div>
        <ProgressBar :value="progressValue" />
      </div>
      <div class="mt-[-25px]">
        <Grade />
      </div>
    </div>
    <div class="mx-6 mt-8 mb-14">
      <div class="text-neutral-600 text-xl leading-[27px] mb-4">
        Assessment report
      </div>
      <div class="opacity-90 w-[90%] text-neutral-600 text-xs">
        {{ getAssessmentReport() }}
       See Details: <br/><br/>
       <ol  v-for="(report, index) in reports"
      :key="index">
        <li>-{{ report }} <br/><br/></li>
       </ol>
       
      </div>
   
    </div>
  </section>
</template>
<script>
import ProgressBar from "../UI/ProgressBar.vue";
import Grade from "../UI/Grade.vue";
import { inject } from "vue";

export default {
  components: { ProgressBar, Grade },

  setup() {
    const store = inject("store");
    const progressValue = store.score.value;
    const reports = store.reports.value

 // Method to get assessment report based on progressValue
 const getAssessmentReport = () => {
      if (progressValue >= 90) {
        return "Outstanding work! Your streamlined contract management is a testament to your commitment to smooth operations.";
      } else if (progressValue >= 80) {
        return "Well done! Your contract process is generally effective, and with a few optimizations, it can become even more streamlined.";
      }else if (progressValue >= 70) {
        return "Decent effort! While your contract management is okay, consider refining certain aspects to make the process smoother.";
      } else if (progressValue >= 60) {
        return "Needs improvement. Your contract process has significant gaps, impacting overall efficiency.";
      }
      else if (progressValue >= 50) {
        return "Significant improvements are necessary. Your contract process is hindering efficiency and may need a thorough overhaul.";
      } else {
        return "Urgent action is needed. Revise your contract management strategy to avoid negative impacts on your business objectives.";
      }
    };

    return {
      progressValue,
getAssessmentReport,
reports
    };
  },
};
</script>