<template>
  <div>
    <router-view/>
    <ScrollToTop />
  </div>
</template>

<script>
import { provide } from 'vue';
import store from './components/store';
import ScrollToTop from './components/ScrollToTop.vue'; 

export default {
  name: 'App',
  setup() {
    provide('store', store);
  },
  components: {
    ScrollToTop, 
  },
};
</script>
