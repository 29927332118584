<template>
    <div
        class="bg-cover bg-center font-lighter relative tracking-wider leading-7 flex flex-col text-white md:text-sm text-xs sm:p-8 p-4 rounded-xl"
        :style="`background-image: url(${require('@/assets/contact.svg')})`"
    >
        <div class="rectangle">
            <p class="md:text-base text-sm">Contact Information</p>
            <p class="flex my-8">
                <Icon :class="yellowIcon" icon="ic:baseline-phone" />+27 72 699 8012
            </p>
            <p class="flex  my-8">
                <Icon :class="yellowIcon" icon="ic:baseline-email" /> admin@lawyeredup.co.za
            </p>
          
        </div>
        <!-- i need this div to sit at the far bottom of the container -->
        <div class="flex space-x-8 mt-auto">
            <Icon icon="ri:twitter-x-fill" />
            <Icon icon="akar-icons:instagram-fill" />
            <Icon icon="cib:linkedin-in" />
            <Icon icon="ri:facebook-fill" />
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import { Icon } from "@iconify/vue";

export default {
    components: { Icon },
    setup() {
        const yellowIcon = ref("sm:mr-4 mr-2 mt-2 sm:mt-0.5 text-orange-500");
        return { yellowIcon };
    },
};
</script>
