<template>
  <div class="mx-4 md:mx-14 my-4 md:my-8 md:w-[45%] sm:w-full">
    <div
      class="text-primary text-3xl md:text-6xl mb-4 md:mb-6 leading-[54px] md:leading-[75px]"
    >
      The Vega Assessment
    </div>
    <div
      class="text-secondary text-sm md:text-base mb-4 md:mb-6 leading-[23px] md:leading-[31px]"
    >
     Find out how well you are doing with your contracting process and how we can can help. 
     
     <br/><br/>
     Every successful business needs contracts—they're at the core of it all. 
     But let's be real, dealing with contracts can seriously drain our mojo. 
     Everyone wants us to up our game, yet most legal teams are feeling the struggle of knowing where to start.
    </div>
    <div
      class="text-primary text-xs md:text-sm mb-6 md:mb-8 leading-[20px] md:leading-[27px]"
    >
      Find out your contract processes score in seconds with our quick and easy
      assessment.
    </div>
   
    <router-link to="/assessment">
      <Button buttonText="Take Assessment" />
    </router-link>
  </div>
</template>
  <script>
import Button from "@/components/UI/Button.vue";
export default {
  components: {
    Button,
  },
  setup() {
  
    

    return {
 
  
    };
  },
};
</script>

