<template>
    <div class="mt-8">
  <NavHeaderVue/>
  <SocialInfo/>
  <Clients/>
  <Footer/>
</div>
 </template>
 <script>

import NavHeaderVue from '@/components/layout/NavHeader.vue';

import Clients from '@/components/Dashboard/Clients.vue';
import Features from '@/components/Dashboard/Features.vue';
import Footer from '@/components/layout/Footer.vue';
import SocialInfo from '@/components/Dashboard/SocialInfo.vue';


 export default {
   components: {
     NavHeaderVue, Clients, Features, Footer, SocialInfo
   },
   setup() {
 
     return {
    
     }
   }
 };
 </script>
 