<template>
  <header class="fixed top-0 left-0  w-full bg-white text-primary md:py-2 md:px-4 flex items-center justify-between  z-50">
    <div class="flex items-center space-x-2">
      <img src="@/assets/luv2.png" alt="Company Logo" class="h-11 w-15" />
    </div>
    <NavButtons/>
    <div class="hidden md:flex items-center space-x-4 text-right">
      <a href="https://app.lawyeredapp.com" target="_blank">
        <Button
          buttonText="Get Started"
          backgroundColor="bg-white"
          textColor="text-neutral-500"
          borderColor="border-primary"
          :clickHandler="handleClick"
        />
      </a>
   
        <!-- <Button
          buttonText="Register as a Lawyer"
          :clickHandler="handleClick"
          borderColor="border-primary"
          backgroundColor="bg-primary"
        /> -->
    
    </div>
  </header>
  <div class="w-full border border-zinc-300 border-opacity-60"></div>
</template>

<script>
import { Icon } from "@iconify/vue";
import NavButtons from './NavButtons.vue';
import Button from "../UI/Button.vue";

export default {
  components: { Icon, NavButtons, Button },
  setup() {
    return {};
  },
};
</script>

<style scoped>
/* Additional styling for the header if needed */
header {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: adds shadow for better separation from content */
}
</style>
