<template>
          <div class="absolute max-[280px]:space-y-4 flex flex-col space-y-8 md:flex-row md:justify-between pt-[10%] md:pt-[4%] sm:pt-[10%] lg:pt-[5%] pl-[10%]  ">
            <div class="tracking-wide leading-5">
              <h1 class="text-base sm:text-xl md:text-2xl lg:text-3xl font-medium md:mb-2 tracking-wider leading-5">
                Try Lawyered Up For Free
              </h1>
              <p class="text-[0.7rem] w-[90%] sm:w-[50%] md:text-sm md:tracking-wider max-[280px]:hidden md:leading-4 mt-4 font-light">
                Join hundreds of businesses to streamline your legal 
                processes today
              </p>
            </div>
            <div class="sm:mt-4 lg:ml-48 ">
              <a href="https://app.lawyeredapp.com" target="_blank">
                  <Button
                buttonText="Try for Free"
                backgroundColor="bg-white"
                textColor="text-black"
                borderColor="border-white"
                :clickHandler="handleClick"
              />
              </a>
           
            </div>
          </div>
    
  </template>
    
    <script>
import Button from "../UI/Button.vue";
import { Icon } from '@iconify/vue';
  export default {
    components: {  Button},
  };
  </script>
  