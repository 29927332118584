<template>
    <div class="mt-8">
      <!-- Navigation Header -->
      <NavHeaderVue />
  
      <!-- Careers Hero Section -->
      <div class="bg-gray-100 py-16 text-center">
        <h1 class="text-zinc-750 text-4xl md:text-5xl font-bold mb-4">Join Our Team</h1>
        <p class="text-zinc-800 text-base md:text-lg mb-8 leading-relaxed">
          We are always looking for talented individuals who are passionate about making a difference.
        </p>
      </div>
  
      <!-- Open Positions Section -->
      <div class="container mx-auto py-16">
     
  
        <div v-if="positions.length > 0" class="flex flex-wrap justify-center gap-x-8 gap-y-8">
            <h2 class="text-center text-zinc-750 text-3xl md:text-4xl font-semibold mb-12">Open Positions</h2>
          <div
            v-for="position in positions"
            :key="position.id"
            class="bg-white shadow-lg rounded-lg overflow-hidden w-64 md:w-80"
          >
            <div class="p-6 md:p-8">
              <h3 class="text-zinc-800 text-lg md:text-xl font-semibold mb-1">
                {{ position.title }}
              </h3>
              <p class="text-zinc-800 text-sm md:text-base mb-2">
                {{ position.location }}
              </p>
              <p class="text-zinc-800 text-xs md:text-sm mb-4">
                {{ position.description }}
              </p>
              <router-link :to="'/careers/' + position.slug">
                <button class="inline-flex items-center bg-primary text-white text-xs py-2 px-4 rounded hover:bg-opacity-90 transition-opacity">
                  View Details
                  <Icon icon="weui:arrow-outlined" class="ml-2" />
                </button>
              </router-link>
            </div>
          </div>
        </div>
  
        <div v-else class="text-center py-16">
          <p class="text-zinc-750 text-2xl md:text-3xl font-semibold mb-4">
            No Open Positions
          </p>
          <p class="text-zinc-800 text-base md:text-lg mb-8 leading-relaxed">
            Currently, we have no vacancies, but we are always interested in meeting passionate and talented people. Feel free to check back later or contact us directly.
          </p>
          <router-link to="/contact-us">
            <button class="inline-flex items-center bg-primary text-white text-xs py-2 px-4 rounded hover:bg-opacity-90 transition-opacity">
              Contact Us
              <Icon icon="weui:arrow-outlined" class="ml-2" />
            </button>
          </router-link>
        </div>
      </div>
  
   
  
      <!-- Footer -->
      <Footer />
    </div>
  </template>
  
  <script>
  import { positions } from '@/data/careers';
import NavHeaderVue from '@/components/layout/NavHeader.vue';
  import Footer from '@/components/layout/Footer.vue';
  import JoinOurTeamSection from '@/components/Team/JoinOurTeamSection.vue';
  import { Icon } from '@iconify/vue';
  
  export default {
    components: {
      NavHeaderVue,
      Footer,
      JoinOurTeamSection,
      Icon,
    },
    setup() {
      return {
        positions,
      };
    },
  };
  </script>
  