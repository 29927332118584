<template>
  <div class="home">
    <Header />
    <div class="flex flex-col md:flex-row justify-between">
      <contact-process-info />
      <div class="relative hidden md:block md:w-[50%] sm:w-full">
        <assessment-info class="relative z-2" />
        <AssessmentDisplay
          class="absolute ml-80 -translate-x-1/2 -translate-y-1/2 z-10"
        />
      </div>
    </div>
    <ContractImprove />
  </div>
</template>

<script>
import Header from "@/components/layout/Header.vue";
import ContactProcessInfo from "@/components/ContactUs/ContactProcessInfo.vue";
import AssessmentInfo from "@/components/Assessment/AssessmentInfo.vue";
import Assessment from "@/components/Assessment/Assessment.vue";
import ContractImprove from "@/components/ContactUs/ContractImprove.vue";
import AssessmentDisplay from "@/components/Assessment/AssessmentDisplay.vue";
import Result from "@/components/Assessment/Result.vue";
import { ref } from "vue";

export default {
  components: {
    Header,
    ContactProcessInfo,
    AssessmentInfo,
    Assessment,
    ContractImprove,
    AssessmentDisplay,
    Result,
  },
  setup() {
    const showModal = ref(false);

    const openModal = () => {
      showModal.value = true;
    };

    const closeModal = () => {
      showModal.value = false;
    };

    return {
      showModal,
      openModal,
      closeModal,
    };
  },
};
</script>
