<template>
  <div class="flex flex-wrap mt-2">
    <label
      v-for="(option, index) in options"
      :key="index"
      class="flex items-center mr-2 mb-2 cursor-pointer"
    >
      <div class="flex items-center">
        <input
          type="radio"
          :id="option"
          v-model="selectedOption"
          :value="option"
          @change="handleRadioChange"
          class="appearance-none"
        />
        <div :class="{ 'w-4 h-4 bg-white rounded-[5px] border mr-2 ml-4': true, 'border-4 border-green-800': selectedOption === option }"></div>
      </div>
      {{ option.name }}
    </label>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    preSelectedOption: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const selectedOption = ref(props.preSelectedOption);
   let score = 0
  let rep = ''
    // Watch for changes in the preSelectedOption prop
    watch(() => props.preSelectedOption, (newValue) => {
      selectedOption.value = newValue;
    });

    const handleRadioChange = () => {
      score = selectedOption.value.score
      rep= selectedOption.value.report
      emit('change', score, rep);
    };

    return {
      selectedOption,
      handleRadioChange,
    };
  },
};
</script>
