<template>
  <div class="lg:mt-24 mt-12">
  
    <div
      v-if="buttonText"
      :class="[
           'inline-block py-2 px-4 mb-8  bg-opacity-15 rounded-full text-xs cursor-default', 
        btnClass
      ]"
    >
      {{ buttonText }}
    </div>
    
    <h2 class="text-primary text-3xl md:text-4xl mb-4 md:mb-6 font-semibold leading-tight tracking-wide">
      {{ title }}
    </h2>
    <p class="text-text1 text-justify md:text-base text-sm md:font-normal font-light md:leading-7 leading-5">
      {{ description }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      default: null,
    },
    btnClass: {
      type: String,
      default: '', 
    },
  },
};
</script>
