// This file contains the data for the careers page
export const positions = [
    // {
    //   title: 'Frontend Developer',
    //   location: 'Remote',
    //   description: 'We are looking for a talented Frontend Developer to join our team and work on innovative web applications.',
    //   details: 'As a Frontend Developer, you will collaborate with the design and backend teams to create responsive and accessible user interfaces. Proficiency in React, Vue.js, and modern CSS is required.',
    //   slug: 'frontend-developer',
    // },

    // Add more positions or leave the array empty for the no vacancies state
  ];
  