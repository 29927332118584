<template>
  <div class="">
    <div class="m-4 mt-2 flex h-[12px] w-[180px] bg-gray-300 rounded-xl h-full transition-all ease-in-out">
      <div :style="{ width: `${Math.min(value, 20)}%` }" class="flex-1 rounded-l-md bg-red-500"></div>
      <div :style="{ width: `${Math.min(Math.max(value - 20, 0), 20)}%` }" class="flex-1 bg-orange-500"></div>
      <div :style="{ width: `${Math.min(Math.max(value - 40, 0), 20)}%` }" class="text-amber-500 flex-1 bg-amber-500">.</div>
      <div :style="{ width: `${Math.min(Math.max(value - 60, 0), 20)}%` }" class="flex-1 bg-lime-500"></div>
      <div :style="{ width: `${Math.min(Math.max(value - 80, 0), 20)}%` }" class="flex-1 rounded-r-md bg-green-500"></div>
    </div>

    <!-- <img :style="{ left: `${Math.min(value * 0.7, 100)}%` }" src="@/assets/polygon.svg" class="absolute mt-[-15px]" alt=""> -->
  </div>
</template>

<script setup>
import { ref } from "vue";

const value = ref(70); 
</script>
