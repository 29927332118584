<template>
    <div class="text-center bg-gray-50 py-12">
      <div class="text-zinc-750 text-3xl md:text-5xl mb-4 md:mb-6">
        Join Our Team
      </div>
      <div class="text-zinc-800 text-base md:text-lg mb-8 md:mb-12 leading-relaxed w-full md:w-2/3 mx-auto">
        Are you passionate about making a difference? We are always looking for talented individuals to join our team and contribute to our mission of transforming the contracting landscape.
      </div>
      <router-link to="/careers">
        <button class="inline-flex items-center bg-transparent text-xs py-1 px-3 rounded border border-primary text-primary hover:opacity-70 hover:text-white hover:bg-primary transition-colors">
          View Open Positions
          <Icon icon="weui:arrow-outlined" class="ml-2" />
        </button>
      </router-link>
    </div>
  </template>
  
  <script>
  import { Icon } from '@iconify/vue';
  
  export default {
    components: {
      Icon,
    },
  };
  </script>
  