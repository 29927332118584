<template>
    <button 
      v-if="isVisible" 
      @click="scrollToTop" 
      class="fixed bottom-6 right-6 p-3 bg-primary text-white rounded-full shadow-lg hover:opacity-80 transition-colors duration-300"
      aria-label="Scroll to top"
    >
      ↑
    </button>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isVisible: false,
      };
    },
    methods: {
      scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
      handleScroll() {
        this.isVisible = window.scrollY > 300;
      },
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.handleScroll);
    },
  };
  </script>

  