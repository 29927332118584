

export const faqs = [
    {
      question: "What is Lawyered Up powered by Vega?",
      answer:
        "Lawyered Up is an AI-driven legal platform designed to simplify legal processes for SMEs and startups in Africa, offering services such as contract generation, compliance management, and HR support using AI.",
    },
    {
      question: "How does the AI document generation work?",
      answer:
        "Our AI technology automates the creation of legal documents by using customizable templates and analyzing clauses, making the process faster and reducing errors.",
    },
    {
      question: "Is my data secure on the platform?",
      answer:
        "Yes, we prioritize data security. All documents and information are stored securely in the cloud with encryption and access controls to ensure confidentiality. We are also Google-backed.",
    },
    {
      question: "What types of contracts can I generate?",
      answer:
        "Lawyered Up allows you to generate various contracts, including employment agreements, non-disclosure agreements, service contracts, and more, tailored to your specific needs.",
    },
    {
      question: "Can I access the platform from anywhere?",
      answer:
        "Absolutely! Our cloud-based platform is accessible from any device with an internet connection, allowing you to manage your legal needs anytime, anywhere.",
    },
    {
      question: "Do I need legal expertise to use the platform?",
      answer:
        "No legal expertise is required. Our user-friendly interface and AI guidance make it easy for anyone to navigate and utilize our services effectively.",
    },
    {
      question: "What support is available if I have questions?",
      answer:
        "We offer customer support through various channels, including email and WhatsApp chat (+2349099330756), to assist you with any questions or issues you may encounter. Contact us at vega@lawyeredapp.com.",
    },
    {
      question: "How does Lawyered Up support social impact initiatives?",
      answer:
        "Lawyered Up is committed to democratizing access to legal services, particularly for victims of gender-based violence, by providing affordable legal support and fostering inclusivity.",
    },
    {
      question: "What are the costs associated with using Lawyered Up?",
      answer:
        "We offer competitive pricing based on the services you require. For detailed pricing information, please visit our pricing page or contact our support team.",
    },
    {
      question: "How can I get started with Lawyered Up?",
      answer:
        "Getting started is easy! Simply sign up on our website, choose the services you need, and begin using our platform to streamline your legal operations.",
    },
  ];
  