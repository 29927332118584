<template>
   <div  class=" justify-center items-center grid" style="justify-items:center ;">
        <div class="col-start-1 row-start-1" style=" margin-top: 1.5rem;" >
            <img src="../../assets/google.png"  
        />
        </div>
        <div class="col-start-1 " style="text-align: center;">
            <span class="text-md text-primary font-bold">Powered by Vega, a Google Startup</span>
        </div>
      </div>
  
  </template>
    
    <script>
    
    
    export default {
     
      data() {
        return {
  
        };
      },
    };
    </script>
    
  
    