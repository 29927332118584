<template>
  <section class="mx-12 mt-12">
    <section :class="sectionClasses">
      <FeatContent
        class="w-full lg:w-[35%]"
        :title="'Instant Contract Creation'"
        :description="'Simplify contract creation for all teams across your organisation using automation. You can save time by choosing from our extensive library of contract templates, and with our powerful and intuitive free text editing tool you can easily make amendments and edits at any time.'"
        buttonText="Contract Generation"
        btnClass="text-[#1B3D47] bg-[#1B3D47]"
      />
      <div :class="imageContainer">
        <img :src="images.contract" alt="" />
      </div>
    </section>
    <section :class="sectionClasses" class="flex flex-col-reverse">
      <div :class="imageContainer">
        <img :src="images.management" alt="" />
      </div>
      <FeatContent
      class="w-full lg:w-[35%]"
        :title="'Seamless Contract Management'"
        :description="'Simplify contract creation for all teams across your organisation using automation. You can save time by choosing from our extensive library of contract templates, and with our powerful and intuitive free text editing tool you can easily make amendments and edits at any time.'"
        buttonText="Contract Management"
        btnClass="text-[#FDAD1C] bg-[#FDAD1C]"
      />
    </section>
    <section :class="sectionClasses" >
      <FeatContent
      class="w-full lg:w-[35%]"
        :title="'Never Miss A Deadline Again'"
        :description="'Elevate your task management experience with our real-time oversight of company obligations, ranging from HR filings to contract renewals. Our platform offers a streamlined approach ensuring you stay on top of every responsibility.'"
             buttonText="Task Management"
        btnClass="text-[#699635] bg-[#699635]"
        />
      <div :class="imageContainer">
        <img :src="images.deadline" alt="" />
      </div>
    </section>
    <section
      :class="sectionClasses"
      class="flex flex-col-reverse"
    >
      <div :class="imageContainer">
        <img :src="images.team" alt="" />
      </div>
      <FeatContent
           class="w-full lg:w-[40%]"
        :title="'Empower Every Department'"
        :description="'Seamlessly integrate all team members from any department into Vega, the versatile platform provided by Lawyered Up. Whether it`s HR, Finance, Marketing, or your legal team, Vega offers an intuitive solution that caters to all.'"
            buttonText="Team Members"
        btnClass="text-[#1B3D47] bg-[#1B3D47]"
        />
    </section>
  </section>
</template>
<script>
import FeatContent from "./FeatContent.vue";
import { ref } from "vue";
import { images } from "@/data/images";

export default {
  components: {
    FeatContent,
  },

  setup() {
    const sectionClasses = ref(
      "  lg:flex lg:flex-row lg:space-x-24 lg:item-center lg:justify-center  lg:my-12 my-8"
    );
    const imageContainer = ref("w-80% md:block lg:w-[55%] py-8");
    const image1 = ref("relative z-2 ");
    const image2 = ref(
      "absolute -translate-x-1/2 max-[280px]:hidden max-[450px]:ml-[14rem] max-[375px]:ml-[12rem] max-[500px]:w-64 max-[375px]:w-60 ml-[18rem] lg:ml-[18rem] -translate-y-1/2 mt-[-6rem] z-10"
    );

    return {
      sectionClasses,
      imageContainer,
      image1,
      image2,
      images,
    };
  },
};
</script>