<template>
  <div class="my-12 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2">
    <div v-for="company in companyList" :key="company.name" class="flex flex-col items-center mb-4">
      <img :src="company.image" alt="Company Logo" class="w-16 h-16 company-logo " />
      <span class="text-sm text-gray-300">{{ company.name }}</span>
    </div>
  </div>
</template>

<script>
import { images } from '../../data/images'; 

export default {
  data() {
    return {
      companyList: [
        { name: "Google for Startups", image: images.google },
        { name: "Africa Forbes", image: images.forbes },
        { name: "Cape Town Daily", image: images.capeTown },
        { name: "Grindstone Ventures", image: images.grind },
        { name: "Africa Arena", image: images.africaArena },
        { name: "TWeb", image: images.tweb },
        { name: "Digital Street", image: images.digitalStreet },
        { name: "Biz Community", image: images.biz },
        { name: "Think Room", image: images.thinkRoom },
        { name: "Tech Cabal", image: images.techCabal }
      ],
    };
  },
};
</script>

<style scoped>
.company-logo {
  object-fit: contain; /* Ensures the image fits well within its container */
}
</style>
