
export default [
    {
        image: 'https://firebasestorage.googleapis.com/v0/b/lawyeredupwebsite.appspot.com/o/Team%20Members%2FSerisha%2Fserisha.png?alt=media&token=ae6e7f7a-e0b3-44d1-9f12-e7b1459035c9',
        name: 'Serisha',
        position: 'CEO',
        description: 'Serisha is a visionary entrepreneur  with a decade long experience in the tech industry. Her leadership is characterised by a strong commitment to social impact and innovation shaping the future of legal tech',
      },
      {
        image: 'https://firebasestorage.googleapis.com/v0/b/lawyeredupwebsite.appspot.com/o/Team%20Members%2FDeji%2FDeji%20bio%20pic.jpeg?alt=media&token=d0fbae3a-026e-4fbf-9af4-22033fde1ba2',
        name: 'Deji',
        position: 'CTO',
        description: "Deji is a qualified lawyer with pan-African experience as internal and external counsel. He is also a fullstack developer who oversees the development and maintenance of the company's products. He is integral to the transition from legal service and compliance to technical products.",
      },
      // {
      //   image: '',
      //   name: 'Ayo',
      //   position: '',
      //   description: '',
      // },
      {
        image: 'https://firebasestorage.googleapis.com/v0/b/lawyeredupwebsite.appspot.com/o/Team%20Members%2FGreat%2FDSC_9982%20(1).jpg?alt=media&token=c71ffb0c-556b-4012-829e-d1071a80e45e',
        name: 'Great',
        position: 'Software Engineer',
        description: 'Great`s expertise lies in front-end development, where she creates engaging and responsive user interfaces. She also plays a key role in back-end development to ensure smooth functionality, seamless integration, and cohesive software performance.',
      },
    {
      image: 'https://firebasestorage.googleapis.com/v0/b/lawyeredupwebsite.appspot.com/o/Team%20Members%2FGbemi%2FIMG_6269.jpg?alt=media&token=61f824dc-df72-44f8-a236-012bc37757f3',
      name: 'Gbemisola',
      position: 'Software Engineer',
      description: 'Gbemi specialize in front-end development and contribute to back-end development to ensure seamless integration and functionality of the product.',
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/lawyeredupwebsite.appspot.com/o/Team%20Members%2FJoshua%2FJoshua%20Image%201.JPG?alt=media&token=4c258b28-11b9-46f0-9ced-4b1d624d9733",
      name: "Joshua",
      position: "Software developer",
        description: 'Joshua develops web and mobile platforms at Lawyered Up',
      },
      {
        image: 'https://firebasestorage.googleapis.com/v0/b/lawyeredupwebsite.appspot.com/o/Team%20Members%2FDoris%2FIMG-20240619-WA0132.jpg?alt=media&token=58accb43-a956-45b3-a7b2-801d88845c2e',
        name: 'Doris',
        position: 'Legal Associate',
        description: 'Doris is a lawyer in the in-house legal team for Lawyered Up. She is an experienced lawyer with experience ins contract drafting, negotiations and regulatory compliance.',
      },
      {
        image: 'https://firebasestorage.googleapis.com/v0/b/lawyeredupwebsite.appspot.com/o/Team%20Members%2FMmekut%2FMkay%20png_edited_edited_5.jpg?alt=media&token=9897ee16-cd98-4c60-ad6d-c3a85f7ebd39',
        name: 'Mmekut',
        position: 'Product Designer',
        description: 'Mmekut is a UX/UX specialist who oversees the design and research on product features and content creation for Lawyered Up.',
      },
      // {
      //   image: '',
      //   name: 'Roseline',
      //   position: 'Product Associate',
      //   description: 'Roseline is on the product team and is in charge of product management and feature implementation. She also works with the QA testing to ensure quality is mantained across all company products.',
      // },
    
   
    
    
 
  ];
  