<template>
    <section
      class="w-[85%] p-2 bg-white  rounded-[15px] shadow border border-neutral-200 border-opacity-70"
    >
      <header class="text-black text-xl leading-[27px] my-2 ml-4">
    
        Your Contract Assessment Completed
  
      </header>
     <AssessmentReport/>
   
    </section>
  </template>
  <script>
  import ProgressBar from '../UI/ProgressBar.vue';
  import Grade from '../UI/Grade.vue'
  import AssessmentReport from './AssessmentReport.vue';

  export default {
    components: { ProgressBar, Grade, AssessmentReport },
    
    setup() {
  
      return {

      };
    },
  };
  </script>