<template>
    <div
      class="bg-cover bg-center relative p-8"
      :style="`background-image: url(${require('@/assets/bg.png')})`"
    >
      <section class="w-full md:w-[60%] mx-auto">
        <h2
          class="my-4 text-center text-zinc-750 text-4xl md:text-5xl tracking-wide font-semibold"
          :style="'line-height : 1.5'"
        >
          Access
          <span class="text-yellow-500">legal experts</span> from accross the world
        </h2>
  
        <p class="text-center text-zinc-800 md:leading-7 tracking-wide my-4">
          Book a consusltancy with qualified lawyers at your convenience.
        </p>
        <div
          class="flex flex-row items-center my-8 justify-center space-x-4 md:space-y-0 space-x-0 md:space-x-4"
        >
        <a href="https://linktr.ee/lawyeredup" target="_blank" rel="noopener noreferrer">
              <Button buttonText="Request a Demo" :clickHandler="handleClick" />
            </a>
        </div>
      </section>
    </div>
    <section class="mx-12">
      <section :class="sectionClasses">
        <FeatContent
          class="w-full lg:w-2/5"
          :title="'Connect with a Lawyer for Expert Legal Advice'"
          :description="`Schedule a consultation with a qualified lawyer easily through Lawyered Up's platform. Our directory of pre-vetted legal professionals allows you to find the right lawyer for your specific needs.  Simply browse by area of expertise, book a convenient time, and receive expert legal advice online or in person.`"
        />
        <div :class="imageContainer">
          <img src="@/assets/lawyer.png" alt="" />
        </div>
      </section>

    </section>
  </template>
  <script>
  import { Icon } from "@iconify/vue";
  import Button from "../UI/Button.vue";
  import FeatContent from "./FeatContent.vue";
  import { ref } from "vue";
  
  export default {
    components: { Icon, Button, FeatContent },
    setup() {
      const sectionClasses = ref(
        "  lg:flex lg:flex-row lg:justify-between lg:item-center lg:justify-center"
      );
      const imageContainer = ref("w-full md:w-80% md:block lg:w-1/2 py-8");
      return { sectionClasses, imageContainer };
    },
  };
  </script>
  