<template>
  <div class="mt-8">
    <NavHeaderVue/>
    <Assessment :showModal="showModal" :openModal="openModal"/>
    <Result :showModal="showModal" @close-modal="closeModal"/>
  </div>
</template>

<script>
import Header from "@/components/layout/Header.vue";
import ContactProcessInfo from "@/components/ContactUs/ContactProcessInfo.vue";
import AssessmentInfo from "@/components/Assessment/AssessmentInfo.vue";
import Assessment from "@/components/Assessment/Assessment.vue";
import ContractImprove from "@/components/ContactUs/ContractImprove.vue";
import AssessmentDisplay from "@/components/Assessment/AssessmentDisplay.vue";
import Result from "@/components/Assessment/Result.vue";
import { ref } from "vue";
import NavHeaderVue from "@/components/layout/NavHeader.vue";

export default {
  name: "HomeView",
  components: {
    Header,
    ContactProcessInfo,
    AssessmentInfo,
    Assessment,
    ContractImprove,
    AssessmentDisplay,
    Result,
    NavHeaderVue
  },
  setup() {
    const showModal = ref(false);

    const openModal = () => {
      showModal.value = true;
    };

    const closeModal = () => {
      showModal.value = false;
    };

    return {
      showModal,
      openModal,
      closeModal,
    }
  }
};
</script>
